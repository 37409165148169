import cuid from 'cuid';

import {
  Client_OpportunityType,
  Client_ShortlistOpportunityType,
} from '../../generated';
import { generateCollective } from './collective';

export const generateOpportunity = (
  props?: Partial<Client_OpportunityType>,
  n = 0
): Client_OpportunityType => {
  return {
    id: cuid(),
    collective: generateCollective({}, n),
    isFavorite: false,
    platformFees: 10,
    ...props,
  };
};

export const generateShortlistOpportunity = (
  props?: Partial<Client_ShortlistOpportunityType>,
  n = 0
): Client_ShortlistOpportunityType => {
  return {
    ...generateOpportunity(props, n),
    opportunityCaseStudies: [],
    opportunityMembers: [],
    opportunityMembersInvited: [],
    opportunityQuestions: [],
    statuses: [],
    shortlistSettings: {
      opportunityCard: [],
    },
    ...props,
  };
};

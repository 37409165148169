import {
  JobViewArgs,
  JobViewFormattedProps,
  PublicJobViewArgs,
  RecruiterJobViewArgs,
  SearchJobViewArgs,
} from './types';

export function adaptJobData(
  projectViewArgs: JobViewArgs
): JobViewFormattedProps | null {
  switch (projectViewArgs.type) {
    case 'public':
      return adaptPublicJobData(projectViewArgs);
    case 'search':
      return adaptJobSearchData(projectViewArgs);
    case 'recruiter':
      return adaptRecruiterJobData(projectViewArgs);
    default:
      return null;
  }
}

export const adaptPublicJobData = (
  data: PublicJobViewArgs
): JobViewFormattedProps => {
  const { project } = data;

  const company = project.client?.company || project.company;

  return {
    id: project.id,
    slug: project.slug,
    title: project.name,
    description: project.description,
    publishedAt: project.publishedAt,
    expiresAt: project.expirationDate,
    idealStartDate: project.idealStartDate,
    budgetBrief: project.budgetBrief,
    source: project.source,
    skills: project.projectTypes,
    suggestions: project.projectTypeSuggestions,
    company,
    workPreferences: project.workPreferences,
    location: {
      en: project.location?.fullNameEnglish,
      fr: project.location?.fullNameFrench,
    },
    fromTopRecruiter: project.fromTopRecruiter,
  };
};

export const adaptJobSearchData = (
  data: SearchJobViewArgs
): JobViewFormattedProps => {
  const { project } = data;

  const company = project.client?.company || project.company;

  return {
    id: project.id,
    slug: project.slug,
    title: project.name,
    description: project.description,
    publishedAt: project.publishedAt,
    expiresAt: project.expirationDate,
    idealStartDate: project.idealStartDate,
    budgetBrief: project.budgetBrief,
    source: project.source,
    skills: project.projectTypes,
    suggestions: project.projectTypeSuggestions,
    company,
    workPreferences: project.workPreferences,
    location: {
      en: project.location?.fullNameEnglish,
      fr: project.location?.fullNameFrench,
    },
    fromTopRecruiter: project.fromTopRecruiter,
  };
};

export const adaptRecruiterJobData = (
  data: RecruiterJobViewArgs
): JobViewFormattedProps => {
  const { job } = data;

  return {
    id: job.id,
    slug: job.project.slug,
    title: job.project.name,
    description: job.project.description,
    publishedAt: job.createdAt,
    expiresAt: job.project.expirationDate,
    idealStartDate: job.project.idealStartDate,
    budgetBrief: job.project.budgetBrief,
    source: null,
    skills: job.project.projectTypes,
    suggestions: job.project.projectTypeSuggestions,
    workPreferences: job.project.workPreferences,
    company: job.project.client?.company,
    location: {
      en: job.project.location?.fullNameEnglish,
      fr: job.project.location?.fullNameFrench,
    },
    fromTopRecruiter: job.project.fromTopRecruiter,
  };
};

import { Box, Flex, FlexProps, Text } from '@collective/ui';
import { ReactNode } from 'react';

import { HighlightText } from '../../highlighting/highlight-text';

type ProfileRowProps = FlexProps & {
  children: ReactNode;
};

export const ProfileRow = ({ children, ...rest }: ProfileRowProps) => {
  return (
    <Flex
      gap="16px"
      pb="20px"
      mb="20px"
      position="relative"
      sx={{
        '&:after': {
          content: '""',
          background: 'rythm.200',
          position: 'absolute',
          width: '200px',
          height: '1px',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
        },
      }}
      _last={{
        '&:after': { display: 'none' },
        pb: 0,
        mb: 0,
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
};

type ProfileRowHeaderProps = Omit<FlexProps, 'title'> & {
  title: ReactNode | null;
  subtitle?: ReactNode | null;
  location?: string | null;
  dates?: ReactNode;
  actions?: ReactNode;
  highlightIdentifiers?: string[];
};

export const ProfileRowHeader = ({
  title,
  subtitle,
  location,
  dates,
  actions,
  highlightIdentifiers,
  ...rest
}: ProfileRowHeaderProps) => {
  return (
    <Flex w="100%" gap="8px" overflow="hidden" {...rest}>
      <Flex direction="column" overflow="hidden" width="100%">
        <HighlightText identifiers={highlightIdentifiers}>
          <Text
            variant="desktop-m-bold"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            // To avoid text from moving around when buttons are appearing/disappearing and pushing siblings elements
            maxW="90%"
          >
            {title}
          </Text>
        </HighlightText>
        {!!subtitle && (
          <HighlightText identifiers={highlightIdentifiers}>
            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {subtitle}
            </Text>
          </HighlightText>
        )}

        {!!dates && <Box mt={1}>{dates}</Box>}

        {location && (
          <Text color="rythm.700" variant="desktop-s-regular" mt={1}>
            {location}
          </Text>
        )}
      </Flex>

      <Box
        ml="auto"
        display="none"
        _groupHover={{ display: actions ? 'block' : 'none' }}
      >
        {actions}
      </Box>
    </Flex>
  );
};

import cuid from 'cuid';

import { Recruiter_RecruiterType, RecruiterPlan } from '../../generated';
import { generateLightUser } from './user';

export const generateRecruiter = (
  props?: Partial<Recruiter_RecruiterType>
): Recruiter_RecruiterType => {
  const id = props?.id || cuid();

  const groupUsers = (props?.groupUsers || [generateLightUser()]).map(
    (user) => ({ ...user, recruiterId: id })
  );

  return {
    id,
    groupUsers,
    isFreeTrialAvailable: false,
    lists: [],
    permissions: [],
    plan: RecruiterPlan.Premium,
    seenUserIds: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    ...props,
  };
};

import {
  city,
  email,
  firstName,
  lastName,
  linkedInUrls,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Recruiter_CurrentUserType,
  Recruiter_LightUserType,
  Recruiter_UserType,
  TimePreference,
  WorkPreference,
} from '../../generated';

export const generateUser = (
  props?: Partial<Recruiter_UserType>,
  n = 0
): Recruiter_UserType => ({
  id: cuid(),
  firstname: firstName[n],
  lastname: lastName[n],
  linkedInUrl: linkedInUrls[n],
  pictureUrl: undefined,
  dailyRate: 5000,
  workLocation: city[n],
  workPreference: WorkPreference.Flexible,
  timePreference: TimePreference.FullTime,
  missionCount: 0,
  skills: [],
  crmCounter: 0,
  isImported: false,
  isRegistered: true,
  network: { count: 0, users: [] },
  portfolioCaseStudies: [],
  hasPhoneNumber: false,
  ...props,
});

export const generateCurrentUser = (
  props?: Partial<Recruiter_CurrentUserType>,
  n = 0
): Recruiter_CurrentUserType => ({
  id: cuid(),
  email: email[n],
  firstname: firstName[n],
  lastname: lastName[n],
  linkedInUrl: linkedInUrls[n],
  pictureUrl: undefined,
  createdAt: new Date().toISOString(),
  isAdmin: false,
  isProfileEnabled: true,
  isRecruiter: true,
  hasUsedOneSearch: false,
  oneSearchMonthlyLimit: '20+',
  remainingOneSearchCount: '20+',
  ...props,
});

export const generateLightUser = (
  props?: Partial<Recruiter_LightUserType>,
  n = 0
): Recruiter_LightUserType => ({
  id: cuid(),
  firstname: firstName[n],
  lastname: lastName[n],
  pictureUrl: undefined,
  ...props,
});

import * as adminFixtures from './admin';
import * as clientFixtures from './client';
import * as collectiveFixtures from './collective';
import * as recruiterFixtures from './recruiter';

export const fixtures = {
  admin: adminFixtures,
  client: clientFixtures,
  collective: collectiveFixtures,
  recruiter: recruiterFixtures,
  // to backport old syntax and have the collective fixtures as default common ones
  ...collectiveFixtures,
};

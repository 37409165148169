import {
  AvailabilityStatus,
  Client_FullMemberFragment,
  Collective_GetNetworkUser,
  Collective_SearchUsers,
  PublicPages_GetPublicUser,
  Recruiter_SearchUsers,
  WorkPreference,
} from '@collective/data-type';
import {
  Box,
  Flex,
  IconCheck,
  IconCompany,
  IconMapPin,
  IconMoney,
  IconRocket,
  Text,
  Tooltip,
} from '@collective/ui';
import {
  ArrayElement,
  displayCurrencyFromNumber,
} from '@collective/utils/helpers';
import dayjs from 'dayjs';
import { TFunction, Trans, useTranslation } from 'react-i18next';

import { UserPill } from './user-pill';

type RecruiterUser =
  | Omit<
      ArrayElement<Recruiter_SearchUsers['results']['users']>,
      'isImported' | 'crmCounter'
    >
  | ArrayElement<Collective_SearchUsers['results']['users']>;
type NetworkUser = Collective_GetNetworkUser['user'];
type PublicProfileUser = PublicPages_GetPublicUser['user'];
type ClientUser = Client_FullMemberFragment['user'] & {
  availabilityValidUntil?: never;
  availabilityStatus?: never;
};

type UserType = NonNullable<
  ClientUser | RecruiterUser | NetworkUser | PublicProfileUser
>;

type UserPillsProps = {
  user: UserType;
};

export const UserPills = ({ user }: UserPillsProps) => {
  const { t } = useTranslation('common');

  return (
    <Flex mt={5} gap={2} flexWrap="wrap" _empty={{ mt: 0 }}>
      {!!user.dailyRate && (
        <UserPill
          icon={<IconMoney color="primary.500" size="sm" />}
          content={
            <Trans
              i18nKey="profile.tags.dailyRate"
              components={{
                dark: <Text variant="desktop-m-medium" as="span" />,
              }}
              values={{
                rate: displayCurrencyFromNumber(user.dailyRate),
              }}
              ns="common"
            />
          }
          colorScheme="blue"
        />
      )}
      <AvailabilityPill user={user} />
      {!!user.missionCount && (
        <UserPill
          icon={<IconRocket color="rythm.600" size="xs" />}
          content={t('profile.tags.missionAchieved', {
            count: user.missionCount,
          })}
          colorScheme="basic"
        />
      )}
      {user.workLocation && (
        <UserPill
          icon={<IconMapPin color="rythm.600" size="xs" />}
          content={user.workLocation}
          colorScheme="basic"
        />
      )}
      {user.workPreference && (
        <UserPill
          icon={<IconCompany color="rythm.600" size="sm" />}
          content={getWorkPreferenceLabel(t, user.workPreference)}
          colorScheme="basic"
        />
      )}
    </Flex>
  );
};

const AvailabilityPill = ({ user }: UserPillsProps) => {
  const { t } = useTranslation('common');

  const isInternalAvailabilityConfirmed =
    user.availabilityValidUntil &&
    user.availabilityStatus &&
    dayjs().isBefore(user.availabilityValidUntil) &&
    [AvailabilityStatus.ReadyForInterview].includes(user.availabilityStatus);

  const isEnrichedAvailabilityConfirmed =
    'availabilityStatusEnriched' in user &&
    user.availabilityStatusEnriched &&
    [AvailabilityStatus.ReadyForInterview].includes(
      user.availabilityStatusEnriched
    );

  if (isInternalAvailabilityConfirmed || isEnrichedAvailabilityConfirmed) {
    return (
      <Tooltip label={t('profile.tags.readyToInterviewTooltip')}>
        <Box>
          <UserPill
            icon={<IconCheck size="xs" />}
            content={t('profile.tags.readyToInterview')}
            colorScheme="green"
          />
        </Box>
      </Tooltip>
    );
  }

  return null;
};

const getWorkPreferenceLabel = (
  t: TFunction,
  workPreference: WorkPreference | null | undefined
) => {
  switch (workPreference) {
    case WorkPreference.Flexible:
      return t('profile.tags.hybrid');
    case WorkPreference.Remote:
      return t('profile.tags.remoteOnly');
    default:
      return null;
  }
};

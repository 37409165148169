import {
  email,
  paragraphs1,
  productName,
  sentence20,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  ApplicationType,
  Client_ProjectType,
  Client_ShortlistProjectType,
  ProjectStartDate,
  ProjectStep,
  ShortlistPricing,
} from '../../generated';
import { generateClient } from './client';
import { generateUser } from './user';

export const generateProject = (
  props?: Partial<Client_ProjectType>,
  n = 0
): Client_ProjectType => {
  const client = generateClient(undefined, n);

  return {
    id: cuid(),
    name: productName[n],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    description: paragraphs1[n],
    sumUp: sentence20[n],
    idealStartDate: ProjectStartDate.Asap,
    opportunities: [],
    client: {
      ...client,
      company: {
        ...client.company,
        billingEmails: [],
      },
      projects: [],
      user: { ...generateUser(), email: email[n] },
    },
    proposals: [],
    shortlistPricing: ShortlistPricing.None,
    applicationType: ApplicationType.Group,
    step: props?.step || {
      name: ProjectStep.MissionOngoing,
      cancelled: false,
    },
    totalShortlistOpportunities: 0,
    showWorkExperience: false,
    showEducation: false,
    ...props,
  };
};

export const generateShortlistProject = (
  props?: Partial<Client_ShortlistProjectType>,
  n = 0
): Client_ShortlistProjectType => {
  const client = generateClient(undefined, n);

  return {
    id: cuid(),
    name: productName[n],
    description: paragraphs1[n],
    opportunities: [],
    client: {
      ...client,
      company: {
        ...client.company,
        billingEmails: [],
      },
      projects: [],
      user: { ...generateUser(), email: email[n] },
    },
    shortlistPricing: ShortlistPricing.None,
    applicationType: ApplicationType.Group,
    showWorkExperience: false,
    showEducation: false,
    isShortlistEnded: false,
    isRecruiterJob: false,
    projectTypes: [],
    ...props,
  };
};

import {
  BoxProps,
  Flex,
  IconFourPointedStar,
  IconProps,
  Text,
} from '@collective/ui';

type TopRecruiterBadgeProps = {
  label: string;
  iconSize?: IconProps['boxSize'];
} & BoxProps;

export const TopRecruiterBadge = ({
  label,
  iconSize = '.5em',
  ...rest
}: TopRecruiterBadgeProps) => {
  return (
    <Flex
      gap={1}
      w="fit-content"
      alignItems="center"
      p="4px 8px"
      bg="decorative.gold.100"
      borderRadius={8}
      {...rest}
    >
      <IconFourPointedStar
        color="decorative.gold.800"
        w={iconSize}
        h={iconSize}
      />
      {!!label && (
        <Text color="decorative.gold.800" aria-label={label}>
          {label}
        </Text>
      )}
    </Flex>
  );
};

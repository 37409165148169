import { paragraphs2 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Recruiter_RecruiterNoteType } from '../../generated';

export const generateRecruiterNote = (
  props: Omit<Partial<Recruiter_RecruiterNoteType>, 'recruiterId'> & {
    recruiterId: string;
  },
  n = 0
): Recruiter_RecruiterNoteType => {
  return {
    id: cuid(),
    content: paragraphs2[n],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    ...props,
  };
};

import {
  email,
  firstName,
  lastName,
  linkedInUrls,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Client_ClientUserType,
  Client_GetUser,
  Client_UserType,
} from '../../generated';

export const generateUser = (
  props?: Partial<Client_UserType>,
  n = 0
): Client_UserType => ({
  id: cuid(),
  firstname: firstName[n],
  lastname: lastName[n],
  linkedInUrl: linkedInUrls[n],
  pictureUrl: undefined,
  missionCount: 0,
  portfolioCaseStudies: [],
  skills: [],
  ...props,
});

export const generateGetUser = (
  props?: Partial<Client_GetUser['user']>,
  n = 0
): Client_GetUser['user'] => {
  return {
    ...generateUser(props, n),
    email: email[n],
    ...props,
  };
};

export const generateClientUser = (
  props?: Partial<Client_ClientUserType>,
  n = 0
): Client_ClientUserType => ({
  id: cuid(),
  firstname: firstName[n],
  lastname: lastName[n],
  pictureUrl: undefined,
  email: email[n],
  ...props,
});

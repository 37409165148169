import { chain } from 'lodash';
import { useMemo } from 'react';

import { useProjectTypesTranslator } from '../../hooks/use-project-types-translator';

type SkillType = {
  code: string;
  isFeatured?: boolean | null;
  isHighlighted?: boolean;
};

export function useSkillSort(
  skills: SkillType[],
  filterSkills: string[],
  withHighlight = false
) {
  const projectTypeTranslator = useProjectTypesTranslator();

  return useMemo(() => {
    const skillsWithHighlight = withHighlight
      ? skills.map((skill) => ({
          ...skill,
          isHighlighted: filterSkills.includes(skill.code),
        }))
      : skills;

    return chain(skillsWithHighlight)
      .orderBy(
        [(skill) => filterSkills.includes(skill.code), 'isFeatured'],
        ['desc', 'desc']
      )
      .map(({ code, isFeatured, isHighlighted }) => ({
        value: projectTypeTranslator(code),
        isFeatured,
        isHighlighted,
      }))
      .value();
  }, [projectTypeTranslator, skills, filterSkills, withHighlight]);
}

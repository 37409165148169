import { OpportunityStatusType, ShortlistPricing } from '@collective/data-type';
import { isPresent } from '@collective/utils/helpers';
import { displayName } from '@collective/utils/shared';
import { pick } from 'lodash';

import { hasOpportunityBudget } from '../../utils/has-opportunity-budget';
import {
  UserProfileEducation,
  UserProfileLink,
  UserProfileWorkExperience,
} from '../types';
import {
  AdminApplicationViewArgs,
  ApplicationViewArgs,
  ApplicationViewFormattedProps,
  ClientApplicationViewArgs,
  CollectiveApplicationViewArgs,
  NetworkApplicationViewArgs,
  OpportunityMember,
  OpportunityQuestion,
  PublicPagesApplicationViewArgs,
  RecruiterApplicationViewArgs,
} from './types';

export function adaptSoloApplicationData(
  applicationViewArgs: ApplicationViewArgs
): ApplicationViewFormattedProps | null {
  switch (applicationViewArgs.type) {
    case 'collective':
      return adaptCollectiveSoloApplicationData(applicationViewArgs);
    case 'admin':
      return adaptAdminSoloApplicationData(applicationViewArgs);
    case 'client':
      return adaptClientSoloApplicationData(applicationViewArgs);
    case 'recruiter':
      return adaptRecruiterSoloApplicationData(applicationViewArgs);
    case 'network':
      return adaptNetworkSoloApplicationData(applicationViewArgs);
    case 'public-pages':
      return adaptPublicPagesSoloApplicationData(applicationViewArgs);
    case 'loading':
      return getSkeletonData();
    default:
      return null;
  }
}

function adaptCollectiveSoloApplicationData(
  data: CollectiveApplicationViewArgs
): ApplicationViewFormattedProps | null {
  const { opportunity, caseStudies, me, profile, shortlistPricing } = data;

  const opportunityCaseStudies = opportunity.caseStudies
    .map(({ caseStudyId }) => {
      return (
        caseStudies.find((caseStudy) => caseStudy.id === caseStudyId) || null
      );
    })
    .filter(isPresent)
    .map((caseStudy) => caseStudy);

  const workExperiences = profile?.workExperiences || [];
  const educations = profile?.education || [];
  const links = profile?.links || [];

  const member: OpportunityMember = {
    dailyRate: opportunity.members[0].dailyRate,
    position: opportunity.members[0].title,
    pictureUrl: me.pictureUrl,
    fullname: displayName(me),
    linkedInUrl: me.linkedInUrl,
    resumeUrl: opportunity.members[0].resumeUrl,
    phoneNumber: opportunity.phoneNumber,
  };

  const questions: OpportunityQuestion[] = opportunity.questions.map(
    ({ answer, question }) => ({ answer, label: question.name })
  );

  return {
    isFavorite: false,
    noteBanner: null,
    opportunityCaseStudies,
    member,
    questions,
    shortlistPricing,
    workExperiences,
    educations,
    links,
    budgetRange: hasOpportunityBudget(opportunity)
      ? pick(opportunity, [
          'budgetRangeFrom',
          'budgetRangeTo',
          'budgetRangeAnswer',
        ])
      : null,
    opportunityId: opportunity.id,
  };
}

function adaptAdminSoloApplicationData(
  data: AdminApplicationViewArgs
): ApplicationViewFormattedProps | null {
  const { opportunity, caseStudies, shortlistPricing } = data;

  const memberData = opportunity.opportunityMembers[0].member;

  const opportunityCaseStudies = opportunity.opportunityCaseStudies
    .map(({ caseStudy: { id } }) => {
      return caseStudies.find((caseStudy) => caseStudy.id === id) || null;
    })
    .filter(isPresent)
    .map((caseStudy) => caseStudy);

  const workExperiences: UserProfileWorkExperience[] =
    memberData.user.profile?.workExperiences || [];

  const educations = memberData.user.profile?.education || [];

  const links = memberData.user.profile?.links || [];

  const member: OpportunityMember = {
    dailyRate: opportunity.opportunityMembers[0].dailyRate,
    position: opportunity.opportunityMembers[0].title,
    pictureUrl: memberData.pictureUrl,
    fullname: displayName(memberData.user),
    linkedInUrl: memberData.user.linkedInUrl,
    resumeUrl: opportunity.opportunityMembers[0].resumeUrl,
    phoneNumber: opportunity.phoneNumber,
  };

  const questions: OpportunityQuestion[] = opportunity.opportunityQuestions.map(
    ({ answer, question }) => ({ answer, label: question.name })
  );

  const clientNote = opportunity.opportunityNotes.find(
    ({ isPublic }) => isPublic
  );

  const isAccepted = opportunity.statuses.some(
    ({ status }) => status === OpportunityStatusType.AcceptedByClient
  );
  const isDeclined = opportunity.statuses.some(
    ({ status }) => status === OpportunityStatusType.DeclinedByClient
  );

  return {
    status: isAccepted ? 'contacted' : isDeclined ? 'declined' : undefined,
    isFavorite: opportunity.isFavorite,
    noteBanner: clientNote
      ? {
          adminName: displayName(clientNote.createdByUser),
          note: clientNote.content,
          pictureUrl: clientNote.createdByUser.pictureUrl,
        }
      : null,
    opportunityCaseStudies,
    member,
    questions,
    shortlistPricing,
    workExperiences,
    educations,
    links,
    budgetRange: hasOpportunityBudget(opportunity)
      ? pick(opportunity, [
          'budgetRangeFrom',
          'budgetRangeTo',
          'budgetRangeAnswer',
        ])
      : null,
    opportunityId: opportunity.id,
  };
}

function adaptClientSoloApplicationData(
  data: ClientApplicationViewArgs
): ApplicationViewFormattedProps | null {
  const { opportunity, caseStudies, shortlistPricing } = data;

  const memberData = opportunity.opportunityMembers[0].member;

  const opportunityCaseStudies = opportunity.opportunityCaseStudies
    .map(({ caseStudy: { id } }) => {
      return caseStudies.find((caseStudy) => caseStudy.id === id) || null;
    })
    .filter(isPresent)
    .map((caseStudy) => caseStudy);

  const workExperiences: UserProfileWorkExperience[] =
    memberData.user.profile?.workExperiences || [];

  const educations: UserProfileEducation[] =
    memberData.user.profile?.education || [];

  const links: UserProfileLink[] = memberData.user.profile?.links || [];

  const member: OpportunityMember = {
    dailyRate: opportunity.opportunityMembers[0].dailyRate,
    position: opportunity.opportunityMembers[0].title,
    pictureUrl: memberData.pictureUrl,
    fullname: displayName(memberData.user),
    linkedInUrl: memberData.user.linkedInUrl,
    resumeUrl: opportunity.opportunityMembers[0].resumeUrl,
    phoneNumber: opportunity.phoneNumber,
    intro: memberData?.user?.profile?.intro,
  };

  const questions: OpportunityQuestion[] = opportunity.opportunityQuestions.map(
    ({ answer, question }) => ({ answer, label: question.name })
  );

  const isAccepted = opportunity.statuses.some(
    ({ status }) => status === OpportunityStatusType.AcceptedByClient
  );
  const isDeclined = opportunity.statuses.some(
    ({ status }) => status === OpportunityStatusType.DeclinedByClient
  );

  return {
    status: isAccepted ? 'contacted' : isDeclined ? 'declined' : undefined,
    isFavorite: opportunity.isFavorite,
    noteBanner: opportunity.opportunityNote
      ? {
          adminName: displayName(opportunity.opportunityNote.createdByUser),
          note: opportunity.opportunityNote.content,
          pictureUrl: opportunity.opportunityNote.createdByUser.pictureUrl,
        }
      : null,
    opportunityCaseStudies,
    member,
    questions,
    shortlistPricing,
    workExperiences,
    educations,
    links,
    budgetRange: hasOpportunityBudget(opportunity)
      ? pick(opportunity, [
          'budgetRangeFrom',
          'budgetRangeTo',
          'budgetRangeAnswer',
        ])
      : null,
    opportunityId: opportunity.id,
  };
}

function adaptRecruiterSoloApplicationData(
  data: RecruiterApplicationViewArgs
): ApplicationViewFormattedProps | null {
  const { user } = data;

  const workExperiences: UserProfileWorkExperience[] =
    user.profile?.workExperiences || [];
  const educations: UserProfileEducation[] = user.profile?.educations || [];
  const links: UserProfileLink[] = user.profile?.links || [];

  const member: OpportunityMember = {
    dailyRate: user.dailyRate,
    position: user.profile?.title,
    intro: user.profile?.intro,
    pictureUrl: user.pictureUrl,
    fullname: displayName(user),
    linkedInUrl: user.linkedInUrl,
    resumeUrl: null,
  };

  return {
    status: undefined,
    isFavorite: false,
    noteBanner: null,
    opportunityCaseStudies: user.portfolioCaseStudies,
    member,
    questions: [],
    shortlistPricing: ShortlistPricing.DailyRate,
    workExperiences,
    educations,
    links,
    budgetRange: null,
    opportunityId: '-',
    network: user.network,
    onLinkClick: data.onLinkClick,
  };
}

function adaptNetworkSoloApplicationData(
  data: NetworkApplicationViewArgs
): ApplicationViewFormattedProps | null {
  const { user } = data;

  const member: OpportunityMember = {
    dailyRate: user.dailyRate,
    position: user.profile?.title,
    intro: user.profile?.intro,
    pictureUrl: user.pictureUrl,
    fullname: displayName(user),
    linkedInUrl: user.linkedInUrl,
    resumeUrl: null,
  };

  return {
    status: undefined,
    isFavorite: false,
    noteBanner: null,
    opportunityCaseStudies: user.caseStudies,
    member,
    questions: [],
    shortlistPricing: ShortlistPricing.DailyRate,
    workExperiences: user.profile?.workExperiences || [],
    educations: user.profile?.education || [],
    links: user.profile?.links || [],
    budgetRange: null,
    opportunityId: '-',
    network: user.network,
  };
}

function adaptPublicPagesSoloApplicationData(
  data: PublicPagesApplicationViewArgs
): ApplicationViewFormattedProps | null {
  const { user } = data;

  const workExperiences: UserProfileWorkExperience[] =
    user.profile?.workExperiences || [];
  const educations: UserProfileEducation[] = user.profile?.educations || [];
  const links: UserProfileLink[] = user.profile?.links || [];

  const member: OpportunityMember = {
    dailyRate: user.dailyRate,
    position: user.profile?.title,
    intro: user.profile?.intro,
    pictureUrl: user.pictureUrl,
    fullname: displayName(user),
    linkedInUrl: user.linkedInUrl,
    resumeUrl: null,
  };

  return {
    status: undefined,
    isFavorite: false,
    noteBanner: null,
    opportunityCaseStudies: user.caseStudies,
    member,
    questions: [],
    shortlistPricing: ShortlistPricing.DailyRate,
    workExperiences,
    educations,
    links,
    budgetRange: null,
    opportunityId: '-',
    network: user.network,
  };
}

function getSkeletonData(): ApplicationViewFormattedProps | null {
  return {
    status: undefined,
    isFavorite: false,
    noteBanner: null,
    opportunityCaseStudies: [],
    member: { fullname: '' },
    questions: [],
    shortlistPricing: ShortlistPricing.DailyRate,
    workExperiences: [],
    educations: [],
    links: [],
    budgetRange: null,
    opportunityId: '-',
    isLoading: true,
  };
}

import { ShortlistPricing } from '@collective/data-type';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconFile,
  Link,
  Skeleton,
  Text,
} from '@collective/ui';
import { ALL_IDENTIFIER, TITLE_IDENTIFIER } from '@collective/utils/frontend';
import { times } from 'lodash';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { HighlightText } from '../../highlighting/highlight-text';
import { PhoneButton } from '../../phone-modal';
import { SoloApplicationDailyRateBadge } from './solo-application-daily-rate-badge';
import { SoloApplicationNetworkStats } from './solo-application-network-stats';
import { ApplicationViewNetworkStats, OpportunityMember } from './types';

type SoloApplicationProfileBannerProps = {
  member: OpportunityMember;
  shortlistPricing: ShortlistPricing;
  additionalTags?: ReactNode;
  cta?: ReactNode;
  network?: ApplicationViewNetworkStats;
};

export const SoloApplicationProfileBanner = ({
  member: { fullname, position, dailyRate, resumeUrl, phoneNumber },
  shortlistPricing,
  network,
  additionalTags,
  cta,
}: SoloApplicationProfileBannerProps) => {
  const { t } = useTranslation('common');

  return (
    <SoloApplicationProfileBannerBase
      fullName={
        <Heading as="h1" mb={1} variant="desktop-xl-bold">
          {fullname}
        </Heading>
      }
      position={
        !!position && (
          <Text as="h2" mb={2} variant="desktop-m-medium">
            {position}
          </Text>
        )
      }
      networkConnections={<SoloApplicationNetworkStats network={network} />}
      dailyRateBadge={
        !additionalTags &&
        (shortlistPricing === ShortlistPricing.DailyRate ||
          shortlistPricing === ShortlistPricing.BudgetRange) && (
          <SoloApplicationDailyRateBadge dailyRate={dailyRate} />
        )
      }
      rightButtons={
        <>
          <PhoneButton phoneNumber={phoneNumber} shortVersion />
          <Box>
            {resumeUrl && (
              <Link href={resumeUrl} textDecoration="none" target="_blank">
                <Button variant="secondary" rightIcon={<IconFile />}>
                  {t('profile.application.links.resume')}
                </Button>
              </Link>
            )}
            {cta}
          </Box>
        </>
      }
      additionalTags={!!additionalTags && additionalTags}
    />
  );
};

export const SoloApplicationProfileBannerLoader = () => {
  return (
    <SoloApplicationProfileBannerBase
      fullName={<Skeleton height="30px" w="250px" mb={1} />}
      position={<Skeleton height="18px" w="320px" mb={1} />}
      networkConnections={
        <Flex align="center" gap="8px">
          <Skeleton height="26px" w="75px" mb={1} />
          <Skeleton height="18px" w="100px" />
        </Flex>
      }
      dailyRateBadge={undefined}
      additionalTags={
        <>
          <Flex gap={2} mb={1}>
            {times(5).map((value) => (
              <Skeleton key={value} height="28px" w="250px" />
            ))}
          </Flex>
          <Flex gap={1} pt="20px">
            {times(5).map((value) => (
              <Skeleton key={value} height="28px" w="90px" />
            ))}
          </Flex>
        </>
      }
    />
  );
};

type SoloApplicationProfileBannerBaseProps = {
  fullName: ReactNode;
  position: ReactNode;
  networkConnections: ReactNode;
  dailyRateBadge: ReactNode;
  rightButtons?: ReactNode;
  additionalTags?: ReactNode;
};

const SoloApplicationProfileBannerBase = ({
  fullName,
  position,
  networkConnections,
  dailyRateBadge,
  rightButtons,
  additionalTags,
}: SoloApplicationProfileBannerBaseProps) => {
  return (
    <>
      <Flex
        gap="8px"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <HighlightText identifiers={[TITLE_IDENTIFIER, ALL_IDENTIFIER]}>
          <Box flex={1}>
            {fullName}
            {position}
            {networkConnections}
            {dailyRateBadge}
          </Box>
        </HighlightText>

        {rightButtons}
      </Flex>
      {additionalTags}
    </>
  );
};
